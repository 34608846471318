import { memo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './index.less';
import classnames from 'classnames';
import { postNativeRequestPromise } from '../../utils/naviteRequestUtil';
// import { Toast } from '_antd-mobile@2.3.4@antd-mobile';
import { Toast } from 'antd-mobile';

function LoveStationWishes() {
  const [activeIndex, setActiveIndex] = useState(0);
  const history = useHistory();
  const [list, setList] = useState([]);
  const [question, setQuestion] = useState('');
  const [noMessage, setNoMessage] = useState('');
  const [record, setRecord] = useState();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    postNativeRequestPromise("/inner/h5/visit/addRecord", {pageName: 'stationWishes'}).then(resp => {
      console.log(resp);
    })
  }, [])

  const handleGoMenu = () => {
    // history.push('/love/station-menu');
    history.goBack();
  };
  const handleChange = index => {
    setActiveIndex(index);
  };
  useEffect(() => {
    getCurrentMonthRecord();
  }, []);
  //获取抽奖记录
  const getCurrentMonthRecord = () => {
    postNativeRequestPromise('/inner/h5/wish/prize/getCurrentMonthRecord', {})
      .then(res => {
        if (res.data && res.data.prizeDrawTime) {
          setRecord(res.data);
        } else {
          getWish();
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  //获取题目
  const getWish = () => {
    postNativeRequestPromise('/inner/h5/wish/prize/getWish', {})
      .then(res => {
        if (res.data.question) {
          setList(res.data.wishOptionsWebVOList);
          setQuestion(res.data);
        } else {
          Toast.info(res.message);
          setNoMessage(res.message);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  //抽奖
  const handlePrizeDraw = () => {
    postNativeRequestPromise('/inner/h5/wish/prize/prizeDraw', {
      optionsId: list[activeIndex].optionsId,
    })
      .then(res => {
        if (res.data == 200) {
          // Toast.info(res.message);
          history.push('/love/station-reward', {
            info: res.data,
          });
        } else {
          Toast.info(res.message);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  return (
    <>
      <div className={styles['love-station-wishes']}>
        <div className={styles['top']}></div>
        <div
          className={styles['activity-rules']}
          onClick={() => setVisible(true)}
        >
          活动规则
        </div>
        {question?.question ? (
          <div className={styles['question']}>
            <div className={styles['title']}>{question?.question}</div>
            {question?.question ? <div className={styles['query']}></div> : ''}
            <div className={styles['list']}>
              {list.map((item, index) => {
                return (
                  <div
                    className={classnames(
                      styles['item'],
                      activeIndex === index ? styles['active_item'] : ''
                    )}
                    onClick={() => handleChange(index)}
                    key={index}
                  >
                    <span className={styles['dot']}></span>
                    <span className={styles['item-text']}>{item.options}</span>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className={styles['question']}>
            <div className={styles['title']}>
              {record ? '已经抽过' : '活动未开始'}
            </div>
            <div className={styles['gift-icon']}></div>
            {record ? (
              <div className={styles['receive-btn']}>
                {`已经抽过!还有${record?.prizeDrawTime}天才能重新抽奖！`}
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        {question?.question ? (
          <>
            <div
              className={styles['draw-prizes-btn']}
              onClick={handlePrizeDraw}
            >
              抽取奖品
            </div>
          </>
        ) : (
          ''
        )}
        <div className={styles['back-btn']} onClick={handleGoMenu}>
          返回
        </div>
        {visible && (
          <div>
            <div className="am-picker-popup-mask"></div>
            <div className="am-picker-popup-wrap " role="dialog">
              <div
                role="document"
                className="am-picker-popup"
                style={{
                  height: '98vh',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  margin: 'auto',
                }}
                onClick={() => setVisible(false)}
              >
                <div
                  className="rule-body"
                  style={{
                    position: 'absolute',
                    left: '63px',
                    top: '22vh',
                    width: '70%',
                    height: '55%',
                  }}
                >
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      活动日期：
                    </span>
                    {/* 2024年8月28日-11月30日 */}
                    2024年9月中旬（具体以活动开始日期为准）-11月30日
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      活动门店：
                    </span>
                    广州市范围内肯德基餐厅
                  </p>
                  <p
                    style={{
                      color: '#fff',
                      fontSize: '14px',
                      lineHeight: '20px',
                      letterSpacing: '.5px',
                      fontWeight: 300,
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 'bolder',
                      }}
                    >
                      活动规则：
                    </span>
                    每月(自然月)抽出100份免费餐点卡券送给户外劳动者，未中奖参与者可获得9.9元指定中杯现磨咖啡券 一张。
                    {/* 每月抽出100份免费餐点卡券送给户外劳动者，未中奖参与者可获得9.9元指定中杯现磨咖啡券
                    一张。 */}
                    {/* 自然周领取一次，领取后自然周有效。 */}
                  </p>

                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
export default memo(LoveStationWishes);
